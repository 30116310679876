import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Hotjar from '@hotjar/browser';

const siteId = 5120628;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);


ReactDOM.render(  <BrowserRouter><App /></BrowserRouter>     , document.getElementById("root"));
